import { Item } from './segments/item';
import { Placeholder } from './segments/placeholder';

export class Spinner {
  constructor(el, options) {
    this._el = el;
    this._options = options;
    this._animated = false;
    this._build();
  }

  async animate() {
    if (window.matchMedia) {
      const media = window.matchMedia('(prefers-reduced-motion: reduce)');
      if (!media || media.matches) {
        return Promise.resolve();
      }
    }

    this._animated = true;
    const promises = this._instances.map((instance) => instance.animate());
    await Promise.all(promises);
  }

  async resize() {
    if (!this._animated) {
      return;
    }

    const promises = this._instances.map((instance) => instance.resize());
    await Promise.all(promises);
  }

  _build() {
    const el = this._el;
    const text = el.textContent;
    const segments = text.trim().split('');

    let position = 0;
    const instances = segments.map((value) => {
      const number = parseInt(value, 10);
      const isNumber = !isNaN(number) && number.toString() === value;

      if (!isNumber) {
        return new Placeholder(value);
      }

      const spinner = new Item(number, position, this._options);
      position += 1;
      return spinner;
    });
    this._instances = instances;

    const mask = document.createElement('span');
    mask.className = 'text-spinner__mask';
    mask.setAttribute('role', 'presentation');
    this._mask = mask;

    el.innerHTML = '';
    el.setAttribute('aria-label', text);
    el.appendChild(mask);
    instances.forEach((instance) => mask.appendChild(instance.el));
  }

  destroy() {
    this._instances.forEach((instance) => instance.destroy());

    this._el.removeChild(this._mask);
    this._mask = null;

    this._el.textContent = this._el.getAttribute('aria-label');
    this._el.removeAttribute('aria-label');
  }
}
