import React from 'react';

const FactBox = (props) => {
  const { clients, team_members, website, duration, service_areas } = props;

  return (
    <>
      {(clients || service_areas || duration || team_members || website) && (
        <div className="fact-box">
          <div className="fact-box__wrap">
            {clients && (
              <div className="fact-box__item">
                <div className="fact-box__key">Client</div>
                {clients.map((client, i) => (
                  <div className="fact-box__value-wrap" key={i}>
                    <div className="fact-box__value">{client.name}</div>
                  </div>
                ))}
              </div>
            )}
            {service_areas && (
              <div className="fact-box__item">
                <div className="fact-box__key">Service</div>
                <div className="fact-box__value-wrap">
                  {service_areas.map((service, i) => (
                    <div className="fact-box__value" key={i}>
                      {service.name}
                    </div>
                  ))}
                </div>
              </div>
            )}
            {duration && (
              <div className="fact-box__item">
                <div className="fact-box__key">Duration</div>
                <div className="fact-box__value-wrap">
                  <div className="fact-box__value">{duration}</div>
                </div>
              </div>
            )}
            {team_members && (
              <div className="fact-box__item">
                <div className="fact-box__key">Team</div>
                <div className="fact-box__value-wrap">
                  <div className="fact-box__value">{`${team_members.length}`} people</div>
                </div>
              </div>
            )}
            {website && (
              <div className="fact-box__item">
                <div className="fact-box__key">Website</div>
                <div className="fact-box__value-wrap">
                  <a
                    className="fact-box__value"
                    target="_blank"
                    rel="noreferrer noopener"
                    href={website}
                  >
                    {website}
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default FactBox;
