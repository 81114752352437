import throttle from 'lodash/throttle';
import React, { useEffect, useRef } from 'react';

import useIntersectionObserver from '../../../hooks/useIntersectionObserver';

import { Spinner } from './utils/spinner';

const CLASS_NAME = 'text-spinner';
const CLASS_ANIMATION = `${CLASS_NAME}--is-spinning`;
const CLASS_COMPLETED = `${CLASS_NAME}--is-completed`;

export const ANIMATION_DURATION = 500;
export const ANIMATION_DELAY = 250;
export const ANIMATION_OFFSET = 50;

const TextSpinner = ({ children, ...options }) => {
  const instance = useRef();
  const [ref, inView, entry] = useIntersectionObserver({
    triggerOnce: true,
    threshold: 1,
  });
  const target = entry?.target;

  useEffect(() => {
    if (!instance.current && target) {
      instance.current = new Spinner(target, options);
    }

    if (!inView) {
      return;
    }

    Promise.resolve()
      .then(() => target.classList.add(CLASS_ANIMATION))
      .then(() => instance.current.animate())
      .then(() => target.classList.add(CLASS_COMPLETED))
      .then(() => target.classList.remove(CLASS_ANIMATION));
  }, [inView, target]);

  useEffect(() => {
    const onResize = throttle(() => instance.current?.resize(), 125);
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);

      if (instance.current) {
        instance.current.destroy();
      }
    };
  }, []);

  return (
    <span ref={ref} className={CLASS_NAME}>
      {children}
    </span>
  );
};

TextSpinner.defaultProps = {
  duration: ANIMATION_DURATION,
  delay: ANIMATION_DELAY,
  offset: ANIMATION_OFFSET,
};

export default TextSpinner;
