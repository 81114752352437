import classNames from 'classnames';
import React from 'react';

import ContactsList from './contacts-list';
import Markdown from './markdown';

const ContentFooter = (props) => {
  const { summary, contacts, isCentered } = props;

  const contentFooterClasses = classNames({
    'content-footer': true,
    'content-footer--centered': isCentered,
  });

  return (
    <footer className={contentFooterClasses}>
      <div className="content-footer__inner">
        {summary && (
          <div className="content-footer__item">
            <div className="content-footer__item-heading">What we did</div>
            <div className="content-footer__project-summary">
              <Markdown markdown={summary.childMarkdownRemark.html} />
            </div>
          </div>
        )}

        {contacts && (
          <div className="content-footer__item content-footer__item--contacts">
            <div className="content-footer__item-heading">Want to know more?</div>
            <ContactsList contacts={contacts} />
          </div>
        )}
      </div>
    </footer>
  );
};
export default ContentFooter;
