export class Placeholder {
  constructor(text) {
    const el = document.createElement('span');
    el.className = 'text-spinner__placeholder';
    el.textContent = text;
    this._el = el;
  }

  get el() {
    return this._el;
  }

  async animate() {}

  async resize() {}

  destroy() {
    this._el.parentElement.removeChild(this._el);
    this._el = null;
  }
}
