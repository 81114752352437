import classNames from 'classnames';
import { graphql } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';
import hex2hsl from 'hex-to-hsl';
import { ParallaxProvider, ParallaxBanner, Parallax } from 'react-scroll-parallax';

import {
  GroupAnimation,
  GroupedTextAnimation,
  GroupedBlockAnimation,
} from '../components/animations/group-animation';
import { DELAY_OFFSET } from '../components/animations/config';

import BlockAnimation from '../components/animations/block-animation';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import blockConductor from '../blocks/block-conductor';
import ContentFooter from '../components/content-footer';
import FactBox from '../components/fact-box';
import Header from '../components/header';
import Image from '../components/image';
import Layout from '../components/layout';
import Markdown from '../components/markdown';
import SEO from '../components/SEO';
import SectionArticleTeaser from '../sections/section-article-teaser';
import SectionFacts from '../sections/section-facts';
import SectionOpener from '../sections/section-opener/section-opener';
import { buildLink, resolve } from '../urls';

const attachCustomAttributes = (domNode) => {
  domNode && domNode.setAttribute('webkit-playsinline', true);
};

const BRIGHTNESS_THRESHOLD = 45; // values less are 'dark', above are 'light';
const BRIGHTNESS_LIGHT = 'light';
const BRIGHTNESS_DARK = 'dark';
const BRIGHTNESS_DEFAULT = BRIGHTNESS_LIGHT;

class SingleCaseStudyPage extends Component {
  static getDerivedStateFromProps(props, state) {
    const { pageContext, data } = props;
    const { contentfulProject: page } = data;
    let nextState = null;

    let background;
    let color;
    if (pageContext.isFeatured) {
      background = page.featuredProjectBannerColor || '#131313';
      color = page.featuredProjectBannerTextColor;
    }

    if (state.background !== background) {
      let brightness = BRIGHTNESS_DEFAULT;
      if (background) {
        const [, , lightness] = hex2hsl(background);
        brightness = lightness > BRIGHTNESS_THRESHOLD ? BRIGHTNESS_LIGHT : BRIGHTNESS_DARK;
      }

      nextState = nextState || {};
      nextState = { ...nextState, background, brightness, color };
    }

    return nextState;
  }

  constructor(props) {
    super(props);

    this.state = {
      background: undefined,
      brightness: BRIGHTNESS_DEFAULT,
    };
  }

  handleLoad = () => {
    // update parallax dimensions when image done loading
    if (typeof window !== 'undefined' && window.ParallaxController) {
      window.ParallaxController.update();

      // Work-around for the image not updating on page load for some strange reason.
    }
    this.forceUpdate();
  };

  componentDidMount() {
    if (this.props.pageContext.isFeatured && typeof window !== 'undefined') {
      window.addEventListener('scroll', this.fadeOut, false);

      // Let the parallax banner mount and render proper offset calcs
      // then set top and bottom back to 0 to resize image to proper size
      // but still get offset benefit
      let layer = document.querySelector('.parallax-banner-layer-0');
      if (layer) {
        layer.style.top = '0px';
        layer.style.bottom = '0px';
        if (this.image) layer.style.backgroundImage = `url(${this.image})`;
      }

      layer = document.querySelector('.parallax-inner');
      if (layer) {
        layer.style.transform = 'none';
      }

      window.addEventListener('load', this.handleLoad);
      window.addEventListener('resize', this.handleLoad);

      this.handleLoad();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.fadeOut);
    window.removeEventListener('load', this.handleLoad);
    window.removeEventListener('resize', this.handleLoad);
  }

  fadeOut() {
    // Fade the opacity of the parallax background onScroll

    const elem = document.querySelector('.case-study__parallax-container')?.clientHeight;
    const heightTrigger = parseInt(elem, 10);

    if (window.scrollY < heightTrigger) {
      const banner = document.querySelector('.case-study__overlay');

      const ratio = 100 / parseInt(heightTrigger, 10);
      const opacityNum = (ratio * window.scrollY) / 100;

      if (banner) {
        banner.style.opacity = opacityNum;
      }
    }
  }

  render() {
    const { background, brightness, color } = this.state;
    const { data, location, pageContext } = this.props;
    const { contentfulProject: page } = data;
    const { clients, team_members, website, duration, service_areas, metadata } = page;
    const factbox = { clients, team_members, website, duration, service_areas };
    const withFactbox = Object.values(factbox).reduce(
      (acc, value) => acc || (!!value && value.length > 0),
      false
    );

    let renderedBlocks = null;

    const isIframe =
      typeof window !== 'undefined'
        ? new URLSearchParams(window.location.search).has('iframe-embed')
        : false;

    if (page.body_blocks) {
      renderedBlocks = page.body_blocks.map((block, i) => blockConductor(block, i));
    }

    const pageWrapClasses = classNames({
      'page': true,
      'page--case-study': true,
    });

    let image = page.main_image ? `${page.main_image.file.url}?q=75` : null;
    if (page.featuredProjectBanner) {
      image = getSrc(page.featuredProjectBanner);
    }

    if (
      typeof window !== 'undefined' &&
      page.featuredProjectBannerMobile &&
      window.innerWidth <= 768
    ) {
      image = getSrc(page.featuredProjectBannerMobile);
    }

    this.image = image;

    let layers = [{ image, amount: 0.4, slowerScrollRate: true }];

    // Override layers in case of video
    if (page.featuredProjectBannerVideo?.file?.url) {
      const children = (
        <video
          src={page.featuredProjectBannerVideo?.file?.url}
          poster={image}
          className="case-study__featured-banner__video"
          loop
          playsInline
          muted
          autoPlay
          ref={attachCustomAttributes}
        />
      );

      layers = [{ children, amount: 0.4, slowerScrollRate: true }];
    }

    const FeaturedBlockWithParallax = () => (
      <div className="case-study__featured-banner">
        <ParallaxBanner
          className="case-study__parallax-container"
          layers={layers}
          style={{
            height: '100vh',
            width: '100vw',
            opacity: 1,
          }}
        >
          <div
            className="case-study__overlay"
            style={{
              background,
            }}
          />
          <Parallax
            className="case-study__section-opener-parallax"
            offsetYMax={40}
            offsetYMin={-40}
            slowerScrollRate={false}
          >
            <SectionOpener>
              <div
                className={`case-study__text-color--${
                  page.featuredProjectBannerTitleTextColor
                    ? page.featuredProjectBannerTitleTextColor
                    : 'white'
                }`}
              >
                <Header heading={page.title} label="Case Study" />
              </div>
            </SectionOpener>
          </Parallax>
        </ParallaxBanner>
      </div>
    );

    const FeaturedBlockWithoutParallax = () => (
      <div
        className=""
        style={{
          background,
          color,
        }}
      >
        <SectionOpener className="section-opener-iframe-correction">
          <div
            className={`case-study__text-color--${
              page.featuredProjectBannerTitleTextColor
                ? page.featuredProjectBannerTitleTextColor
                : 'white'
            }`}
          >
            <Header heading={page.title} label="Case Study" />
          </div>
        </SectionOpener>
      </div>
    );

    const FeaturedBlock = isIframe ? FeaturedBlockWithoutParallax : FeaturedBlockWithParallax;

    return (
      <ParallaxProvider>
        <div className={pageWrapClasses}>
          <Layout navbarIsInverted={pageContext.isFeatured}>
            <SEO
              title={page.title}
              description={page.subtitle}
              shareImage={
                page.featuredProjectBanner
                  ? getSrc(page.featuredProjectBanner)
                  : getSrc(page.main_image)
              }
              metadata={metadata}
            />

            {pageContext.isFeatured && (page.featuredProjectBanner || page.main_image) && (
              <FeaturedBlock />
            )}

            <div
              className={classNames({
                'case-study__intro-background': true,
                'case-study__intro-background--featured': pageContext.isFeatured,
                [`case-study__intro-background--is-${brightness}`]: true,
              })}
              style={{
                background,
                color,
              }}
            >
              {!pageContext.isFeatured && (
                <SectionOpener>
                  <Header heading={page.title} label="Case Study" />
                </SectionOpener>
              )}

              {!pageContext.isFeatured && page.main_image && (
                <div className="case-study__main-image">
                  <Image image={page.main_image} alt={page.main_image.title} />
                </div>
              )}

              <GroupAnimation className="case-study-intro">
                <div
                  className={classNames({
                    'case-study__subtitle-wrap': true,
                    'case-study__subtitle-wrap--with-factbox': withFactbox,
                  })}
                >
                  <GroupedTextAnimation as="h2" delay={DELAY_OFFSET}>
                    {page.subtitle}
                  </GroupedTextAnimation>
                </div>

                <div className="case-study-intro__wrap">
                  <div
                    className={classNames({
                      'case-study-intro__copy': true,
                      'case-study-intro__copy--with-factbox': withFactbox,
                    })}
                  >
                    {withFactbox && (
                      <GroupedBlockAnimation
                        className="case-study-intro__factbox"
                        delay={DELAY_OFFSET * 2}
                      >
                        <FactBox {...factbox} />
                      </GroupedBlockAnimation>
                    )}
                    {page.description && (
                      <GroupedTextAnimation
                        as="p"
                        className="case-study-intro__text"
                        delay={DELAY_OFFSET * 2}
                      >
                        <Markdown markdown={page.description.childMarkdownRemark.html} />
                      </GroupedTextAnimation>
                    )}
                  </div>
                </div>

                {page.facts && (
                  <div
                    className={classNames(
                      'case-study-intro__facts',
                      `case-study-intro__facts--is-${brightness}`
                    )}
                  >
                    <SectionFacts headline={page.facts.headline} facts={page.facts.facts} />
                  </div>
                )}
              </GroupAnimation>
            </div>
            {/* add PageContext check here */}

            {pageContext.isFeatured && !page.main_video && (
              <div className="case-study__main-image">
                <BlockAnimation className="case-study__main-image-overlap" delay={DELAY_OFFSET}>
                  <Image
                    className="case-study__main-image-overlap-img"
                    image={page.main_image}
                    alt={page.main_image?.title}
                  />
                </BlockAnimation>
              </div>
            )}

            {pageContext.isFeatured && page.main_video && (
              <div className="case-study__main-video">
                <BlockAnimation className="case-study__main-image-overlap">
                  <video
                    src={page.main_video.file.url}
                    poster={page.main_image.file.url}
                    loop
                    playsInline
                    muted
                    autoPlay
                    ref={attachCustomAttributes}
                  />
                </BlockAnimation>
              </div>
            )}

            {renderedBlocks && <>{renderedBlocks}</>}

            <ContentFooter
              summary={page.summary ? page.summary : null}
              contacts={page.contact_persons ? page.contact_persons : null}
            />

            {page.related_reads && (
              <SectionArticleTeaser
                link={buildLink('Get in touch', resolve('pages:detail', { pageSlug: 'offices' }))}
                label="More Reading"
                reading={page.related_reads}
                tall
              />
            )}
          </Layout>
        </div>
      </ParallaxProvider>
    );
  }
}

export default SingleCaseStudyPage;

export const pageQuery = graphql`
  query ($slug: String!) {
    contentfulProject(slug: { eq: $slug }) {
      id
      title
      slug
      subtitle
      tile_image_regular {
        title
        file {
          contentType
        }
        gatsbyImageData(layout: CONSTRAINED, width: 500)
      }
      tile_image_tall {
        title
        file {
          contentType
        }
        gatsbyImageData(layout: CONSTRAINED, width: 500)
      }
      main_image {
        title
        file {
          contentType
        }
        gatsbyImageData(layout: FULL_WIDTH, width: 1500, quality: 80)
      }
      main_video {
        title
        file {
          contentType
          url
        }
      }
      main_image_is_dark
      description {
        childMarkdownRemark {
          html
        }
      }
      ...CaseStudyBlocksFragment
      summary {
        childMarkdownRemark {
          html
        }
      }
      facts {
        headline
        facts {
          __typename

          ... on ContentfulBlockIconFact {
            icon
            description
          }

          ... on ContentfulBlockNumericFact {
            value
            description
            textBeforeValue
            textAfterValue
          }
        }
      }
      contact_persons {
        name
        shortName
        active_employee
        avatar {
          title
          file {
            contentType
          }
          gatsbyImageData(layout: CONSTRAINED, width: 200, aspectRatio: 1)
        }
        position
        slug
        email
        office {
          city
        }
      }
      clients {
        id
        name
      }
      service_areas {
        id
        name
        slug
      }
      team_members {
        id
      }
      website
      duration
      archived
      case_date
      related_reads {
        ... on Node {
          ... on ContentfulMagazine {
            __typename
            contentful_id
            title
            slug
            post_category
            teaser {
              childMarkdownRemark {
                html
              }
            }
            main_image {
              title
              file {
                contentType
              }
              gatsbyImageData(layout: CONSTRAINED, width: 1091, quality: 80)
            }
          }
        }
        ... on Node {
          ... on ContentfulProject {
            __typename
            contentful_id
            title
            slug
            subtitle
            main_image {
              title
              file {
                contentType
              }
              gatsbyImageData(layout: CONSTRAINED, width: 1091, quality: 80)
            }
            tile_image_tall {
              title
              file {
                contentType
              }
              gatsbyImageData(layout: CONSTRAINED, width: 500)
            }
            featuredProjectBanner {
              title
              file {
                contentType
              }
              gatsbyImageData(layout: FULL_WIDTH, quality: 80)
            }
          }
        }
      }
      featuredProjectBanner {
        file {
          contentType
          url
        }
        gatsbyImageData(layout: CONSTRAINED, width: 2000, quality: 80)
      }
      featuredProjectBannerMobile {
        file {
          contentType
          url
        }
        gatsbyImageData(layout: FULL_WIDTH, quality: 100)
      }
      featuredProjectBannerVideo {
        title
        file {
          contentType
          url
        }
      }
      featuredProjectBannerColor
      featuredProjectBannerTextColor
      featuredProjectBannerTitleTextColor
      metadata {
        ...Metadata
      }
    }
  }
`;

SingleCaseStudyPage.propTypes = {
  children: PropTypes.node,
  navbarIsInverted: PropTypes.bool,
};
