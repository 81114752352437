import React from 'react';

import { ReactComponent as IconGlobe } from '../assets/images/icons/facts/globe.svg';
import { ReactComponent as IconPageViews } from '../assets/images/icons/facts/page-views.svg';
import { ReactComponent as IconPeopleAudience } from '../assets/images/icons/facts/people-audience.svg';
import { ReactComponent as IconRevenueChart } from '../assets/images/icons/facts/revenue-chart.svg';
import { ReactComponent as IconRevenueDollar } from '../assets/images/icons/facts/revenue-dollar.svg';
import { ReactComponent as IconRevenueEuro } from '../assets/images/icons/facts/revenue-euro.svg';
import { ReactComponent as IconRevenuePound } from '../assets/images/icons/facts/revenue-pound.svg';

const ICONS = {
  'globe': IconGlobe,
  'page-views': IconPageViews,
  'people-audience': IconPeopleAudience,
  'revenue-chart': IconRevenueChart,
  'revenue-euro': IconRevenueEuro,
  'revenue-pound': IconRevenuePound,
  'revenue-dollar': IconRevenueDollar,
};

const BlockFactIcon = (props) => {
  const { icon, description } = props;
  const Icon = ICONS[icon] || (() => null);

  return (
    <div className="fact-icon-block">
      <span className="fact-icon-block__icon" role="presentation">
        <Icon />
      </span>
      <p className="fact-icon-block__description">{description}</p>
    </div>
  );
};

export default BlockFactIcon;
