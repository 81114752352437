import React from 'react';

import TextSpinner, { ANIMATION_DELAY } from '../components/animations/text-spinner';
import { DELAY_OFFSET } from '../components/animations/config';

const BlockFactNumeric = (props) => {
  const { value, textBeforeValue, textAfterValue, description, index } = props;

  return (
    <div className="fact-numeric-block">
      <dl className="fact-numeric-block__definition">
        <dt className="fact-numeric-block__description">{description}</dt>
        <dd className="fact-numeric-block__value">
          <span className="fact-numeric-block__value__before" data-value={textBeforeValue?.trim()}>
            {textBeforeValue?.trim()}
          </span>
          <TextSpinner delay={ANIMATION_DELAY + index * DELAY_OFFSET}>{value}</TextSpinner>
          <span className="fact-numeric-block__value__after" data-value={textAfterValue?.trim()}>
            {textAfterValue?.trim()}
          </span>
        </dd>
      </dl>
    </div>
  );
};

export default BlockFactNumeric;
