import React from 'react';

import BlockFactIcon from '../blocks/block-fact-icon';
import BlockFactNumeric from '../blocks/block-fact-numeric';
import { GroupAnimation, GroupedBlockAnimation } from '../components/animations/group-animation';
import { DELAY_OFFSET } from '../components/animations/config';

const SectionFacts = (props) => {
  const { headline, facts } = props;

  return (
    <div className="section section-facts">
      <h2 className="section-facts__headline">{headline}</h2>
      <GroupAnimation as="ul" className="section-facts__list">
        {facts?.map((fact, index) => {
          switch (fact.__typename) {
            case 'ContentfulBlockIconFact':
              return (
                <GroupedBlockAnimation
                  as="li"
                  key={fact.description}
                  className="section-facts__fact"
                  delay={DELAY_OFFSET * index}
                >
                  <BlockFactIcon {...fact} index={index} />
                </GroupedBlockAnimation>
              );
            case 'ContentfulBlockNumericFact':
              return (
                <GroupedBlockAnimation
                  as="li"
                  key={fact.description}
                  className="section-facts__fact"
                  delay={DELAY_OFFSET * index}
                >
                  <BlockFactNumeric {...fact} index={index} />
                </GroupedBlockAnimation>
              );
            default:
              return null;
          }
        })}
      </GroupAnimation>
    </div>
  );
};

export default SectionFacts;
