import { Animation } from './item/animation';

const CLASSNAME_ITEM = 'text-spinner__item';
const CLASSNAME_VALUE = `${CLASSNAME_ITEM}__value`;

export class Item {
  constructor(value, position, options) {
    this._value = value;
    this._position = position;
    this._options = options;

    const el = document.createElement('span');
    el.className = CLASSNAME_ITEM;
    this._el = el;

    this._values = Array.from({ length: 11 }).map((_, index) => {
      const item = document.createElement('span');
      item.textContent = index % 10;
      item.className = CLASSNAME_VALUE;
      if (index === value) {
        item.className += ` ${CLASSNAME_VALUE}--is-value`;
      }

      return item;
    });

    el.append(...this._values);
  }

  get el() {
    return this._el;
  }

  get value() {
    return this._value;
  }

  get values() {
    return this._values;
  }

  get position() {
    return this._position;
  }

  get options() {
    return this._options;
  }

  async animate() {
    const animation = new Animation(this);
    await animation.start();
  }

  async resize() {
    const noAnimationOptions = { duration: 0, delay: 0, offset: 0 };

    const animation = new Animation(this, noAnimationOptions);
    await animation.start();
  }

  destroy() {
    this._values.forEach((value) => this._el.removeChild(value));
    this._values = null;
    this._options = null;
    this._el.parentElement.removeChild(this._el);
    this._el = null;
  }
}
