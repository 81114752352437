export class Animation {
  constructor(item, options = {}) {
    this._item = item;
    this._options = { ...item.options, ...options };
  }

  async start() {
    const { el, position, value, values } = this._item;
    const { duration, delay, offset } = this._options;
    const height = values[0].clientHeight;

    await new Promise((resolve) => {
      const keyframes = [
        { transform: 'translateY(0)' },
        { transform: `translateY(-${height * value}px)` },
      ];
      const animation = el.animate(keyframes, {
        duration,
        delay: delay + offset * position,
        easing: 'cubic-bezier(0.33, 0, 0.67, 1)',
        fill: 'forwards',
      });
      animation.onfinish = resolve;
    });
  }
}
